/* eslint-disable @typescript-eslint/no-explicit-any */
import { GlobalStyle } from './styles/global';
import { ThemeProvider } from './styles/ThemeProvider';
import { AppRoutes } from './config/routes';
import { IntercomProvider } from 'react-use-intercom';
import { ToastContainer } from 'react-toastify';
import { initTagManager } from 'config/services/initTagManager';
import { useEffect } from 'react';
import { SourceIntegrationProvider } from 'providers/source-integration-context/source-integration-provider';
import { ChosenProductsProvider } from 'providers/chosen-products-context/chosen-products.provider';
import { ResellerProvider } from 'providers/reseller-context/reseller.provider';
import { LeadDataProvider } from 'providers/lead-data-context/lead-data.provider';
import { SummaryProvider } from 'providers/summary-context/summary.provider';
import { OrderProvider } from 'providers/order-context/order.provider';
import { initMoengage } from 'config/services/moengage/initMoengage';
import Smartlook from 'smartlook-client';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

export function App() {
  const intercomId = `${process.env.REACT_APP_INTERCOM_APP_ID}`;
  const smartLookKey = `${process.env.REACT_APP_SMART_LOOK_KEY}`;
  const location = useLocation();

  // eslint-disable-next-line
  if ((window as any).Moengage?.onsite?.displayManager)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).Moengage.onsite.displayManager.initialInlineBodyStyle = '';

  useEffect(() => {
    const body = document.body;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'style'
        ) {
          const currentStyles = body.getAttribute('style');

          if (currentStyles && currentStyles.includes('overflow: hidden')) {
            if ((window as any).Moengage?.onsite?.displayManager) {
              (
                window as any
              ).Moengage.onsite.displayManager.initialInlineBodyStyle = '';
            }
          }
        }
      });
    });

    observer.observe(body, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    initTagManager();
  }, []);
  Smartlook.init(smartLookKey);

  useEffect(() => {
    initMoengage();
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageView'
      }
    });
  }, [location]);

  return (
    <IntercomProvider appId={intercomId}>
      <SourceIntegrationProvider>
        <ChosenProductsProvider>
          <ResellerProvider>
            <LeadDataProvider>
              <SummaryProvider>
                <OrderProvider>
                  <ThemeProvider>
                    <GlobalStyle />
                    <AppRoutes />
                    <ToastContainer />
                  </ThemeProvider>
                </OrderProvider>
              </SummaryProvider>
            </LeadDataProvider>
          </ResellerProvider>
        </ChosenProductsProvider>
      </SourceIntegrationProvider>
    </IntercomProvider>
  );
}
