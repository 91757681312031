import { ReactNode, useEffect, useState } from 'react';
import { SourceIntegrationContext } from './source-integration-context';
import { useLocation, useSearchParams } from 'react-router-dom';
import { uuid } from 'uuidv4';
import { readStorage, writeStorage } from 'hooks/useLocalStorage';
import { IntegrationAuthenticationUseCase } from 'use-cases/auth/integration-auth.use-case';
import { useLoadingState } from 'hooks/useLoadingState';
import { Source } from 'types/Source';

interface ISourceIntegrationProviderProps {
  children: ReactNode;
}

export function SourceIntegrationProvider({
  children
}: ISourceIntegrationProviderProps) {
  const { finishLoading, isLoading, startLoading } = useLoadingState();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const sourceIntegration = searchParams.get('isource') as Source;
  const sourceIntegrationCookie = readStorage('sourceIntegration') as Source;
  const [integrationAuthErr, setIntegrationAuthErr] = useState<string | null>(
    null
  );

  const integration: Integration = {
    source: defineSource(),
    sessionToken: uuid(),
    configurations:
      initialConfiguration[defineSource()] || initialConfiguration['default']
  };

  function defineSource() {
    if (sourceIntegration) {
      return sourceIntegration || 'default';
    } else {
      const subdomain = window.location?.host?.split('.appgas')[0];
      const sourceFromSubdomain = subdomain.replace('-staging', '');

      if (subdomain.split(':')[0] == 'localhost') {
        return 'default' as Source;
      }

      if (sourceFromSubdomain && sourceFromSubdomain !== 'app')
        return sourceFromSubdomain as Source;
      else if (sourceIntegrationCookie) {
        if (location.pathname == '/') {
          return 'default' as Source;
        } else {
          return sourceIntegrationCookie || 'default';
        }
      } else return 'default' as Source;
    }
  }

  async function integrationAuthentication() {
    startLoading();

    await new IntegrationAuthenticationUseCase()
      .handle(integration.source)
      .then((data) => {
        writeStorage('access_token', data.access_token);
      })
      .catch((e) => {
        const responseError = e.response?.data;
        responseError?.message && setIntegrationAuthErr(responseError.message);
      })
      .finally(() => {
        finishLoading();
      });
  }

  useEffect(() => {
    integrationAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    writeStorage('sourceIntegration', defineSource());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SourceIntegrationContext.Provider
      value={{ integration, isLoading, integrationAuthErr }}
    >
      {children}
    </SourceIntegrationContext.Provider>
  );
}

type Configuration = {
  theme: string;
  terms: boolean;
};

export type Integration = {
  source: Source;
  configurations: Configuration;
  sessionToken: string;
};

const initialConfiguration = {
  default: {
    theme: 'appgas',
    terms: true
  } as Configuration,
  alelo: {
    theme: 'alelo',
    terms: true
  } as Configuration,
  aleloec: {
    theme: 'aleloec',
    terms: true
  } as Configuration,
  ifood: {
    theme: 'ifood',
    terms: true
  } as Configuration,
  jeitto: {
    theme: 'jeitto',
    terms: true
  } as Configuration,
  bancomercantil: {
    theme: 'bancomercantil',
    terms: true
  } as Configuration,
  c6bank: {
    theme: 'c6bank',
    terms: true
  } as Configuration,
  credz: {
    theme: 'credz',
    terms: true
  } as Configuration,
  creditas: {
    theme: 'creditas',
    terms: true
  } as Configuration,
  picpay: {
    theme: 'picpay',
    terms: true
  } as Configuration,
  ecobonuz: {
    theme: 'ecobonuz',
    terms: true
  } as Configuration,
  kaledo: {
    theme: 'kaledo',
    terms: true
  } as Configuration,
  credsystem: {
    theme: 'appgas',
    terms: true
  } as Configuration,
  consigaz: {
    theme: 'consigaz',
    terms: true
  } as Configuration,
  sindrodsantos: {
    theme: 'sindrodsantos',
    terms: true
  } as Configuration,
  mastercard: {
    theme: 'mastercard',
    terms: true
  } as Configuration,
  paoclass: {
    theme: 'paoclass',
    terms: true
  } as Configuration,
  kaloo: {
    theme: 'appgas',
    terms: true
  } as Configuration,
  parceiros: {
    theme: 'appgas',
    terms: true
  } as Configuration,
  bradesco: {
    theme: 'bradescocartoes',
    terms: true
  } as Configuration,
  elo: {
    theme: 'elo',
    terms: true
  } as Configuration,
  caixaelo: {
    theme: 'caixaelo',
    terms: true
  } as Configuration,
  socialbank: {
    theme: 'socialbank',
    terms: true
  } as Configuration
};
