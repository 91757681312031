import * as S from './style';
import Box from '@mui/material/Box';
import {
  Avatar,
  Button,
  ClickAwayListener,
  Divider,
  Menu,
  Stack,
  Typography,
  lighten,
  useMediaQuery,
  CardMedia
} from '@mui/material';
import Typograph from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { useHeader } from 'hooks/useHeaderContext';

import { AppGasRoundedIcon } from '../LogoRounded';
import { Container } from '../Container';
import { useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeadDataContext } from 'providers/lead-data-context/lead-data.context';
import React, { useContext, useEffect, useState } from 'react';
import {
  CancelOutlined,
  CheckCircleOutline,
  ExitToApp,
  ExpandMore,
  PlaceOutlined,
  SmsFailedOutlined
} from '@mui/icons-material';
import { FaqLogo } from 'components/FaqLogo';
import { useAddresses } from 'hooks/useAddressesContext';
import logo from '../../views/Home/assets/appgas.png';
import { ResellerContext } from 'providers/reseller-context/reseller-context';
import { ChosenProductsContext } from 'providers/chosen-products-context/chosen-products-contex';
import logoWhite from './assets/appgas-white.png';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';
import { SummaryContext } from 'providers/summary-context/summary.context';

interface IProps {
  children?: React.ReactNode;
}

export function Header({ children }: IProps) {
  const { setSummaryData } = useContext(SummaryContext);
  const theme = useTheme();
  const location = useLocation().pathname;

  const isHome = location == '/';
  const isOrderCanceledPage = location == '/order-cancelled';
  const isOrderRatingPage = location == '/order-rating';

  const { integration } = useContext(SourceIntegrationContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { setReseller } = useContext(ResellerContext);
  const { setChosenProducts } = useContext(ChosenProductsContext);

  const { leadData, logoutUser, isLoadingUserData } =
    useContext(LeadDataContext);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { title, color, editAddress } = useHeader();

  const { setIsAddressModalOpen, setAddressDataInput } = useAddresses();

  const [headerBackgroundColor, setHeaderBackgroundColor] = useState<string>(
    theme.palette.primary.gradient
      ? theme.palette.primary.gradient
      : theme.palette.primary.xxdark
  );

  function isPrimaryColor(valor: string): boolean {
    for (const key in theme.palette.primary) {
      if (
        theme.palette.primary[key as keyof typeof theme.palette.primary] ===
        valor
      ) {
        return true;
      }
    }
    return false;
  }

  function handleLogout() {
    setSummaryData(null);
    setAddressDataInput(null);
    logoutUser();
    setReseller(null);
    setChosenProducts([]);
  }

  useEffect(() => {
    if (theme.palette.primary.gradient && isPrimaryColor(color)) {
      setHeaderBackgroundColor(theme.palette.primary.gradient);
    } else {
      setHeaderBackgroundColor(color);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const navigate = useNavigate();

  const handleClickAvatar = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function hasOwnLogoOnTheme() {
    if (theme.logo) {
      theme.logo.props = {
        width: 48,
        height: 48
      };

      return theme.logo;
    }

    return <AppGasRoundedIcon width={48} height={48} />;
  }

  function textExcerptLength(text: string) {
    if (text !== undefined) {
      if (isSmallScreen && text.length >= 48)
        return `${text.substring(0, 48)}...`;

      if (text.length >= 37) return `${text.substring(0, 37)}...`;

      return text;
    } else {
      return text;
    }
    return text;
  }

  function openRocketChat() {
    window.dispatchEvent(new Event('openRocketChat'));
  }

  return (
    <Box sx={{ height: '64px' }}>
      <S.HeaderBar sx={{ background: headerBackgroundColor }}>
        <Container maxWidth={isHome ? '1200px' : undefined}>
          <Toolbar
            sx={{
              display: 'flex',
              px: '0 !important',
              justifyContent: 'space-between'
            }}
          >
            {isHome ? (
              integration.source === 'socialbank' ? (
                <CardMedia
                  component="img"
                  image={logoWhite}
                  sx={{
                    width: '152px',
                    objectFit: 'contain'
                  }}
                />
              ) : (
                <CardMedia
                  component="img"
                  image={logo}
                  sx={{
                    width: '152px',
                    objectFit: 'contain'
                  }}
                />
              )
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <IconButton sx={{ padding: 0 }} aria-label="home">
                  {hasOwnLogoOnTheme()}
                </IconButton>

                <Box
                  sx={{
                    marginLeft: '10px',
                    paddingRight: '25px',
                    cursor: editAddress ? 'pointer' : 'default'
                  }}
                  onClick={() => editAddress && setIsAddressModalOpen(true)}
                >
                  <Typograph
                    sx={{ fontSize: '16px', color: lighten(color, 0.8) }}
                    fontWeight={'600'}
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    lineHeight={'15px'}
                    gap={'8px'}
                  >
                    {editAddress && (
                      <PlaceOutlined
                        sx={{ color: '#CDF7B7', fontSize: '20px' }}
                      />
                    )}
                    {isOrderCanceledPage && (
                      <CancelOutlined
                        sx={{ color: lighten(color, 0.8), fontSize: '20px' }}
                      />
                    )}
                    {isOrderRatingPage && (
                      <CheckCircleOutline
                        sx={{ color: lighten(color, 0.8), fontSize: '20px' }}
                      />
                    )}
                    {textExcerptLength(title)}
                    {editAddress && (
                      <IconButton size="small">
                        <ExpandMore
                          sx={{ color: lighten(color, 0.8), fontSize: '24px' }}
                        />
                      </IconButton>
                    )}
                  </Typograph>
                </Box>

                {children}
              </Box>
            )}

            <Box display={'flex'} alignItems={'center'} gap={2}>
              {leadData?.id ? (
                <>
                  <IconButton onClick={handleClickAvatar}>
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor: '#fff',
                        color: theme.palette.primary.xxdark,
                        fontWeight: '600',
                        fontSize: '16px'
                      }}
                    >
                      {leadData?.name ? leadData?.name[0] : ''}
                    </Avatar>
                  </IconButton>
                  {open && (
                    <ClickAwayListener onClickAway={handleClose}>
                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1
                            },
                            '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0
                            }
                          }
                        }}
                        transformOrigin={{
                          horizontal: 'right',
                          vertical: 'top'
                        }}
                        anchorOrigin={{
                          horizontal: 'right',
                          vertical: 'bottom'
                        }}
                      >
                        <Stack
                          direction={'row'}
                          gap={'16px'}
                          px={'16px'}
                          minWidth={'150px'}
                          alignItems={'center'}
                        >
                          <Typography
                            variant="body2"
                            fontSize={'12px'}
                            lineHeight={'12px'}
                            fontWeight={'600'}
                            mt={'2px'}
                          >
                            Olá {leadData.name}
                          </Typography>
                        </Stack>
                        <Divider sx={{ mt: '10px', mb: '5px' }} />
                        <Stack px="16px" direction={'column'}>
                          <Stack
                            direction={'row'}
                            gap={'8px'}
                            sx={{ cursor: 'pointer', py: '8px' }}
                            onClick={() => openRocketChat()}
                          >
                            <SmsFailedOutlined
                              sx={{
                                color: theme.palette.primary.xxdark,
                                fontSize: '18px'
                              }}
                            />{' '}
                            <Typography
                              sx={{
                                color: theme.palette.primary.xxdark,
                                fontSize: '12px',
                                fontWeight: '500'
                              }}
                            >
                              Falar com o Appgas
                            </Typography>
                          </Stack>
                          <Stack
                            direction={'row'}
                            gap={'8px'}
                            sx={{ cursor: 'pointer', py: '8px' }}
                            onClick={handleLogout}
                          >
                            <ExitToApp
                              color={'error'}
                              sx={{
                                fontSize: '18px'
                              }}
                            />{' '}
                            <Typography
                              color={'error'}
                              sx={{
                                fontSize: '12px',
                                fontWeight: '500'
                              }}
                            >
                              Sair
                            </Typography>
                          </Stack>
                        </Stack>
                      </Menu>
                    </ClickAwayListener>
                  )}
                </>
              ) : isHome ? (
                <Button
                  sx={{
                    backgroundColor: theme.palette.green.main,
                    height: '26px',
                    textTransform: 'none',
                    fontSize: '12px',
                    lineHeight: '12px',
                    minWidth: 'fit-content'
                  }}
                  variant="contained"
                  size="small"
                  onClick={() => navigate('/create-lead')}
                  disabled={isLoadingUserData}
                >
                  {isLoadingUserData ? 'Aguarde...' : 'Já tenho conta'}
                </Button>
              ) : null}
              {!isHome && !leadData?.token && (
                <IconButton
                  sx={{ padding: 0 }}
                  aria-label="faq"
                  onClick={() => openRocketChat()}
                >
                  <FaqLogo width={27} height={27} />
                </IconButton>
              )}
            </Box>
          </Toolbar>
        </Container>
      </S.HeaderBar>
    </Box>
  );
}
