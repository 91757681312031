import moengage from '@moengage/web-sdk';
import { readStorage } from 'hooks/useLocalStorage';
import { uuid } from 'uuidv4';

export async function initMoengage() {
  const customerId = readStorage('customer_id');
  let moEngageUniqueUserId: string | null = null;

  if (customerId) {
    moEngageUniqueUserId = customerId;
  } else {
    moEngageUniqueUserId = uuid();
  }

  await moengage.initialize({
    app_id: process.env.REACT_APP_MOENGAGE_APP_ID,
    debug_logs: 0,
    cluster: 'DC_4',
    disable_onsite: false
  });
  moengage.add_unique_user_id(moEngageUniqueUserId);
}
